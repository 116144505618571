import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UtilitiesService } from './utilities.service';
import { environment } from '../../environments/environment';


// import { TransferObject, FileUploadOptions, Transfer } from '@ionic-native/transfer';
// import { OneSignal } from '@ionic-native/onesignal';
@Injectable()
export class UserService {

    private accessToken: any = '';

    constructor(public http: HttpClient, private utilitiesService: UtilitiesService, public router: Router) {
        this.accessToken = this.utilitiesService.getData('accessToken', '');

    }

    private extractData(res: any) {

        let body = res.json();
        return body || {};
    }

    private checkToken() {
        this.accessToken = this.utilitiesService.getData('accessToken', '');

    }

    getUsers(search = null, group_id = null, results_page = null, results_no = null, sort = null): Observable<any> {
        let params = '';

        let c = '';
        if (search) {
            c = (params === '' ? '?' : '&');
            params += c + 'search=' + search;
        }

        if (group_id) {
            c = (params === '' ? '?' : '&');
            params += c + 'group=' + group_id;
        }
        if (results_page) {
            c = (params === '' ? '?' : '&');
            params += c + 'results_page=' + results_page;
        }

        if (results_no) {
            c = (params === '' ? '?' : '&');
            params += c + 'results_no=' + results_no;
        }


        if (sort) {
            c = (params === '' ? '?' : '&');
            params += c + 'order_by=' + sort[0];
            if (sort[1]) {
                params += '&order_direction=' + sort[1];
            }
        }
        this.checkToken();
        return this.http.get(environment.ApiUrl + 'users' + params, {
            headers: new HttpHeaders({
                'Authorization': ('Bearer ' + this.accessToken),
                'Content-Type': 'application/json'
            })
        }).pipe(
            tap(
                success => success,
                error => this.errorHandler(error)
            ));
    }

    getSupplierUsers(supplierId): Observable<any> {
        let params = '';

        params += '?supplier_id=' + supplierId + '&results_no=10000';

        this.checkToken();
        return this.http.get(environment.ApiUrl + 'users' + params, {
            headers: new HttpHeaders({
                'Authorization': ('Bearer ' + this.accessToken),
                'Content-Type': 'application/json'
            })
        }).pipe(
            tap(
                success => success,
                error => this.errorHandler(error)
            ));
    }

    getRestaurantUsers(restaurantId): Observable<any> {
        let params = '';

        params += '?restaurant_id=' + restaurantId + '&results_no=10000';

        this.checkToken();
        return this.http.get(environment.ApiUrl + 'users' + params, {
            headers: new HttpHeaders({
                'Authorization': ('Bearer ' + this.accessToken),
                'Content-Type': 'application/json'
            })
        }).pipe(
            tap(
                success => success,
                error => this.errorHandler(error)
            ));
    }

    getUser(id): Observable<any> {
        this.checkToken();
        return this.http.get(environment.ApiUrl + 'user/' + id, {
            headers: new HttpHeaders({
                'Authorization': ('Bearer ' + this.accessToken),
                'Content-Type': 'application/json'
            })
        }).pipe(
            tap(
                success => success,
                error => this.errorHandler(error)
            ));
    }


    login(email, password): Observable<any> {

        let body: any = {
            email: email,
            password: password
        };

        return this.http.post(environment.ApiUrl + 'auth/login', JSON.stringify(body), {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        }).pipe(
            tap(
                success => success,
                error => this.errorHandler(error)
            ));
    }

    logout() {
        let lang = this.utilitiesService.getData('lang', this.utilitiesService.getDeviceLanguage());
        this.utilitiesService.clearData();
        this.utilitiesService.setData('lang', lang);
        this.router.navigate(['/login']);

    }

    sendVerificationCode(email): Observable<any> {

        let body: any = {
            email: email
        };

        return this.http.post(environment.ApiUrl + 'auth/forgetPassword', JSON.stringify(body), {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        }).pipe(
            tap(
                success => success,
                error => this.errorHandler(error)
            ));
    }

    sendVerifyEmail(): Observable<any> {

        this.checkToken();
        return this.http.post(environment.ApiUrl + 'profile/sendVerifyEmail', {}, {
            headers: new HttpHeaders({
                'Authorization': ('Bearer ' + this.accessToken),
                'Content-Type': 'application/json'
            })
        }).pipe(
            tap(
                success => success,
                error => this.errorHandler(error)
            ));
    }

    verifyEmail(data: any): Observable<any> {

        this.checkToken();
        return this.http.post(environment.ApiUrl + 'profile/verifyEmail', data, {
            headers: new HttpHeaders({
                'Authorization': ('Bearer ' + this.accessToken),
                'Content-Type': 'application/json'
            })
        }).pipe(
            tap(
                success => success,
                error => this.errorHandler(error)
            ));
    }

    verifyVerificationCode(email, code): Observable<any> {

        let body: any = {
            email: email,
            code: code
        };

        return this.http.post(environment.ApiUrl + 'auth/checkCode', JSON.stringify(body), {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        }).pipe(
            tap(
                success => success,
                error => this.errorHandler(error)
            ));
    }

    resetPassword(email, code, password): Observable<any> {

        let body: any = {
            email: email,
            code: code,
            password: password
        };

        return this.http.post(environment.ApiUrl + 'auth/newPassword', JSON.stringify(body), {
            'headers': new HttpHeaders({ 'Content-Type': 'application/json' })
        }).pipe(
            tap(
                success => success,
                error => this.errorHandler(error)
            ));
    }

    // get user profile info
    myProfile(): Observable<any> {

        this.checkToken();
        return this.http.get(environment.ApiUrl + 'profile', {
            headers: new HttpHeaders({
                'Authorization': ('Bearer ' + this.accessToken),
                'Content-Type': 'application/json'
            })
        }).pipe(
            tap(
                success => success,
                error => this.errorHandler(error)
            ));
    }

    // get user profile info
    setLanguage(lang): Observable<any> {

        this.checkToken();
        return this.http.put(environment.ApiUrl + 'profile/lang', { lang: lang }, {
            headers: new HttpHeaders({
                'Authorization': ('Bearer ' + this.accessToken),
                'Content-Type': 'application/json'
            })
        }).pipe(
            tap(
                success => success,
                error => this.errorHandler(error)
            ));
    }

    // update user profile info
    updateProfile(userData): Observable<any> {

        this.checkToken();
        return this.http.put(environment.ApiUrl + 'profile', userData, {
            headers: new HttpHeaders({
                'Authorization': ('Bearer ' + this.accessToken),
                'Content-Type': 'application/json'
            })
        }).pipe(
            tap(
                success => success,
                error => this.errorHandler(error)
            ));
    }

    // update user info
    updateUser(userId, userData): Observable<any> {

        this.checkToken();
        return this.http.put(environment.ApiUrl + 'user/' + userId, userData, {
            headers: new HttpHeaders({
                'Authorization': ('Bearer ' + this.accessToken),
                'Content-Type': 'application/json'
            })
        }).pipe(
            tap(
                success => success,
                error => this.errorHandler(error)
            ));
    }

    createUser(userData): Observable<any> {

        this.checkToken();
        return this.http.post(environment.ApiUrl + 'user', userData, {
            headers: new HttpHeaders({
                'Authorization': ('Bearer ' + this.accessToken),
                'Content-Type': 'application/json'
            })
        }).pipe(
            tap(
                success => success,
                error => this.errorHandler(error)
            ));
    }

    deleteUser(userId): Observable<any> {

        this.checkToken();
        return this.http.delete(environment.ApiUrl + 'user/' + userId, {
            headers: new HttpHeaders({
                'Authorization': ('Bearer ' + this.accessToken),
                'Content-Type': 'application/json'
            })
        }).pipe(
            tap(
                success => success,
                error => this.errorHandler(error)
            ));
    }

    // change user password
    changeMyPassword(userData: { old_password: string, password: string }): Observable<any> {
        this.checkToken();
        return this.http.put(environment.ApiUrl + 'profile/changePassword', userData, {
            headers: new HttpHeaders({
                'Authorization': ('Bearer ' + this.accessToken),
                'Content-Type': 'application/json'
            })
        }).pipe(
            tap(
                success => success,
                error => this.errorHandler(error)
            ));
    }


    uploadImage(file: any): Observable<any> {

        this.checkToken();
        let data: FormData = new FormData();
        data.append('photoFile', file, file.name);

        return this.http.post(environment.ApiUrl + 'profilePhoto', data, {
            headers: new HttpHeaders({
                'Authorization': ('Bearer ' + this.accessToken)
            })
        }).pipe(
            tap(
                success => success,
                error => this.errorHandler(error)
            ));

    }

    uploadUserImage(userId, file: any): Observable<any> {

        this.checkToken();
        let data: FormData = new FormData();
        data.append('photoFile', file, file.name);

        return this.http.post(environment.ApiUrl + 'user/' + userId + '/photo', data, {
            headers: new HttpHeaders({
                'Authorization': ('Bearer ' + this.accessToken)
            })
        }).pipe(
            tap(
                success => success,
                error => this.errorHandler(error)
            ));

    }


    signup(userData: any): Observable<any> {
        let data: FormData = new FormData();
        let keys = Object.keys(userData);

        keys.forEach(key => {
            if (typeof userData[key] == "object") {
                if (userData[key].size) {
                    data.append(key, userData[key], userData[key].name);
                } else {
                    let innerKeys = Object.keys(userData[key]);
                    innerKeys.forEach(innerKey => {
                        data.append(key + '[' + innerKey + ']', userData[key][innerKey]);
                    });
                }
            } else {
                data.append(key, userData[key]);
            }
        });

        return this.http.post(environment.ApiUrl + 'auth/signup', data).pipe(
            tap(
                success => success,
                error => this.errorHandler(error)
            ));
    }

    errorHandler(error) {
        if (error.status === '401') {
            this.logout();
        }
    }

}
