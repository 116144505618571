import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PagesService } from 'app/services/pages.service';

@Component({
    selector: 'app-page',
    templateUrl: './page.component.html',
    styleUrls: ['./page.component.scss']
})

export class PageComponent implements OnInit, OnDestroy {

    public pageData: any = {};

    constructor(public route: ActivatedRoute, private pagesServices: PagesService) {

    }


    ngOnInit() {
        let pageId = this.route.snapshot.params.id;
        this.pagesServices.getPage(pageId).subscribe(response => {
            this.pageData = response;
        });

        this.route.params.subscribe(response => {
            if (response.id) {
                this.pagesServices.getPage(response.id).subscribe(response2 => {
                    this.pageData = response2;
                });
            }

        });

    }
    ngOnDestroy() {

    }

}
