import {
    Component,
    OnDestroy,
    OnInit,
    Renderer
    } from '@angular/core';
import { Router } from '@angular/router';
import { ServicesService } from 'app/services/services.service';
import { SimsService } from 'app/services/sims.service';
import { SlidersService } from 'app/services/sliders.service';
import { environment } from 'environments/environment';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit, OnDestroy {

    public slides: any = [];
    public storageUrl = environment.StorageUrl;


    public leftSideUrl = (window as any).leftSideUr;
    public sims: any = [];

    public data = {

    };

    constructor(private router: Router, private servicesService: ServicesService, private simsService: SimsService, private sliders: SlidersService) {
        this.sliders.getSliders(null, 0, 10000).subscribe(response => {
            let data: any = response.sliders;
            this.slides = data;
        });
        this.simsService.getSimsHome(null, null, 1000).subscribe(response => {
            let data: any = response;
            this.sims = data.sims;
            for (let i in this.sims) {
                let id = this.sims[i].id;
                this.sims[i].services = [];
                this.data['sim_' + id] = {
                    page: -1,
                    count: 3,
                    total: this.sims[i].servicesCount,
                    hasMore: true,
                    showLoader: false
                };
                this.getData(id);
            }
        });
    }


    ngOnInit() {

    }
    ngOnDestroy() {

    }

    openLeftSide() {
        if (this.leftSideUrl) {
            if (this.leftSideUrl.indexOf('http') >= 0) {
                window.location.href = this.leftSideUrl;

            } else {
                let url = this.leftSideUrl.split('/');
                this.router.navigate(url);
            }
        }
    }

    clickSlide(slide) {
        if (slide.url) {
            if (slide.url.indexOf('http') >= 0) {
                window.location.href = slide.url;

            } else {
                let url = slide.url.split('/');
                this.router.navigate(url);
            }
        }
    }

    getData(sim_id) {


        this.data['sim_' + sim_id].page++;

        let self = this;

        this.data['sim_' + sim_id].showLoader = true;
        let index = this.sims.findIndex(sim => sim.id == sim_id);

        this.servicesService.getServices(sim_id, this.data['sim_' + sim_id].page, this.data['sim_' + sim_id].count).subscribe(response => {
            let data: any = response;
            this.data['sim_' + sim_id].total = data.count;
            let services: any = data.services;

            if (services.length < this.data['sim_' + sim_id].count) {
                this.data['sim_' + sim_id].hasMore = false;
            } else {
                this.data['sim_' + sim_id].hasMore = true;
            }

            services.forEach(service => {
                self.sims[index].services.push(service);
            });



            this.data['sim_' + sim_id].showLoader = false;


        }, error => {
            this.data['sim_' + sim_id].showLoader = false;
        });


    }
}
