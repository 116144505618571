import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'error',
    pure: false
})
export class ErrorInputPipe implements PipeTransform {

    constructor() {

    }

    transform(value: any, checkValid = false): any {

        if (checkValid) {
            if (value.valid && value.touched) {
                return true;
            } else {
                return false;
            }

        } else {
            if (value.invalid && (value.dirty || value.touched)) {
                return true;
            } else {
                return false;
            }
        }

    }

}
