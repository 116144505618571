import { Location } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { UtilitiesService } from './services/utilities.service';
import {
    Component,
    OnInit,
    DoCheck,
    HostListener,
} from '@angular/core';
declare var ga, $;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, DoCheck {
    public isAdmin = false;

    constructor(public location: Location, private utilities: UtilitiesService, private router: Router) {
        let self = this;
        let routerPath = '';
        this.router.events.subscribe((val) => {
            let event: any = val;

            if (event instanceof NavigationEnd) {
                if (event && event.url) {
                    let eventUrl = event.url.split('?')[0];


                    if (routerPath != eventUrl) {
                        routerPath = eventUrl;
                        if (routerPath.indexOf('admin') < 0) {
                            ga('set', 'page', event.urlAfterRedirects);
                            ga('send', 'pageview');
                        }

                    }

                }

            }
        });

        this.utilities.subscribeEvent('set-admin', () => {
            self.isAdmin = true;
        });
        this.utilities.subscribeEvent('remove-admin', () => {
            self.isAdmin = false;
        });
    }
    ngOnInit() {

    }

    fixedSlide() {
        let length = $('ngb-carousel').length;
        if (length > 0) {
            let width = $('ngb-carousel').width();
            let root = document.documentElement;
            root.style.setProperty('--slide-width', width + 'px');
            root.style.setProperty('--slide-height', (width * 0.6) + 'px');
        }
    }
    ngDoCheck(): void {
        this.fixedSlide();
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.fixedSlide();
    }


}
