import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UserService } from './user.service';
import { UtilitiesService } from './utilities.service';
import { environment } from '../../environments/environment';


@Injectable()
export class PagesService {

    private accessToken: any = '';

    constructor(public http: HttpClient, private utilitiesService: UtilitiesService, private userService: UserService) {
        this.accessToken = this.utilitiesService.getData('accessToken', '');

    }

    private extractData(res: any) {

        let body = res.json();
        return body || {};
    }

    private checkToken() {
        this.accessToken = this.utilitiesService.getData('accessToken', '');

    }

    getPage(id): Observable<any> {

        this.checkToken();
        return this.http.get(environment.ApiUrl + 'pages/' + id, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        }).pipe(
            tap(
                success => success,
                error => this.errorHandler(error)
            ));
    }

    getPages(name = null, results_page = null, results_no = null, sort = null): Observable<any> {
        let params = '';

        let c = '';
        if (name) {
            c = (params === '' ? '?' : '&');
            params += c + 'name=' + name;
        }

        if (results_page) {
            c = (params === '' ? '?' : '&');
            params += c + 'results_page=' + results_page;
        }

        if (results_no) {
            c = (params === '' ? '?' : '&');
            params += c + 'results_no=' + results_no;
        }


        if (sort) {
            c = (params === '' ? '?' : '&');
            params += c + 'order_by=' + sort[0];
            if (sort[1]) {
                params += '&order_direction=' + sort[1];
            }
        }

        this.checkToken();
        return this.http.get(environment.ApiUrl + 'pages' + params, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        }).pipe(
            tap(
                success => success,
                error => this.errorHandler(error)
            ));
    }

    getAllPages(name = null, results_page = null, results_no = null, sort = null): Observable<any> {
        let params = '';

        let c = '';
        if (name) {
            c = (params === '' ? '?' : '&');
            params += c + 'name=' + name;
        }

        if (results_page) {
            c = (params === '' ? '?' : '&');
            params += c + 'results_page=' + results_page;
        }

        if (results_no) {
            c = (params === '' ? '?' : '&');
            params += c + 'results_no=' + results_no;
        }


        if (sort) {
            c = (params === '' ? '?' : '&');
            params += c + 'order_by=' + sort[0];
            if (sort[1]) {
                params += '&order_direction=' + sort[1];
            }
        }

        this.checkToken();
        return this.http.get(environment.ApiUrl + 'pages-all' + params, {
            headers: new HttpHeaders({
                'Authorization': ('Bearer ' + this.accessToken),
                'Content-Type': 'application/json'
            })
        }).pipe(
            tap(
                success => success,
                error => this.errorHandler(error)
            ));
    }

    createPage(data): Observable<any> {

        this.checkToken();
        return this.http.post(environment.ApiUrl + 'pages', data, {
            headers: new HttpHeaders({
                'Authorization': ('Bearer ' + this.accessToken),
                'Content-Type': 'application/json'
            })
        }).pipe(
            tap(
                success => success,
                error => this.errorHandler(error)
            ));
    }

    updatePage(data): Observable<any> {

        this.checkToken();
        return this.http.post(environment.ApiUrl + 'pages/' + data.id, data, {
            headers: new HttpHeaders({
                'Authorization': ('Bearer ' + this.accessToken),
                'Content-Type': 'application/json'
            })
        }).pipe(
            tap(
                success => success,
                error => this.errorHandler(error)
            ));
    }

    deletePage(id): Observable<any> {

        this.checkToken();
        return this.http.delete(environment.ApiUrl + 'pages/' + id, {
            headers: new HttpHeaders({
                'Authorization': ('Bearer ' + this.accessToken),
                'Content-Type': 'application/json'
            })
        }).pipe(
            tap(
                success => success,
                error => this.errorHandler(error)
            ));
    }

    errorHandler(error) {
        if (error.status === '401') {
            this.userService.logout();
        }
    }

}
