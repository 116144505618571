import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginsService } from 'app/services/logins.service';
import { UtilitiesService } from 'app/services/utilities.service';
import { environment } from 'environments/environment';

@Component({
    selector: 'app-admin-login-page',
    templateUrl: './login-page.component.html',
    styleUrls: ['./login-page.component.scss']
})

export class LoginPageComponent implements OnInit, OnDestroy {

    public login: any = { name: '', photo: '' };

    public showDetails = false;

    constructor(public route: ActivatedRoute, private loginsService: LoginsService, private router: Router, private utilities: UtilitiesService) {
        this.login.id = this.route.snapshot.params.id;


    }


    ngOnInit() {
        if (this.login.id) {
            this.loginsService.getLogin(this.login.id).subscribe(response => {
                this.login = response;
                this.login.photo = this.login.photo ? environment.StorageUrl + this.login.photo : '/assets/img/logo.png';

                this.showDetails = true;
            });
        } else {
            this.login.photo = '/assets/img/logo.png';
            this.showDetails = true;

        }


    }

    ngOnDestroy() {

    }

    saveLogin(form) {
        let key = this.login.id ? "updateLogin" : "createLogin";
        let login = this.utilities.clone(this.login);
        delete login.photo;
        delete login.photoFile;
        delete login.uploadPhoto;
        let data;

        if (this.login.uploadPhoto) {
            let photo = this.login.photoFile;
            data = new FormData();

            for (let i in login) {
                data.append(i, login[i]);
            }

            data.append('photoFile', photo, photo.name);

        } else {
            data = login;
        }

        if (form.valid) {
            this.loginsService[key](data).subscribe(response => {
                this.router.navigate(['/admin', 'logins']);
            });
        }
    }

    changePhoto() {
        let self = this;
        this.utilities.getFile().then(file => {
            let fileReader = new FileReader();
            this.login.photoFile = file;
            fileReader.onload = e => {
                self.login.photo = fileReader.result;
                self.login.uploadPhoto = true;
            };

            fileReader.readAsDataURL(file);

        }).catch(error => {

        });
    }

}
