import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SlidersService } from 'app/services/sliders.service';

@Component({
    selector: 'app-admin-sliders',
    templateUrl: './sliders.component.html',
    styleUrls: ['./sliders.component.scss']
})

export class SlidersComponent implements OnInit, OnDestroy {

    public sliders = [];

    constructor(public route: ActivatedRoute, private slidersService: SlidersService) {

    }


    ngOnInit() {
        this.slidersService.getSliders(null, null, 1000).subscribe(response => {
            this.sliders = response.sliders;
        });

    }
    ngOnDestroy() {

    }

    delete(sliderId) {
        var ask = confirm('هل انت متأكد من انك تريد الحذف');
        if (ask) {
            let index = this.sliders.findIndex(item => item.id == sliderId);
            this.slidersService.deleteSlider(sliderId).subscribe(response => {
                this.sliders.splice(index, 1);
            });
        }
    }


}
