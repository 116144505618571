import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RequestsService } from 'app/services/requests.service';

@Component({
    selector: 'app-admin-requests',
    templateUrl: './requests.component.html',
    styleUrls: ['./requests.component.scss']
})

export class RequestsComponent implements OnInit, OnDestroy {

    public requests = [];
    public data = {
        page: -1,
        count: 20,
        hasMore: true,
        showLoader: false
    };

    constructor(public route: ActivatedRoute, private requestsService: RequestsService) {

    }


    ngOnInit() {
        this.getData();

    }
    ngOnDestroy() {

    }

    delete(requestId) {
        var ask = confirm('هل انت متأكد من انك تريد الحذف');
        if (ask) {
            let index = this.requests.findIndex(item => item.id == requestId);
            this.requestsService.deleteRequest(requestId).subscribe(response => {
                this.requests.splice(index, 1);
            });
        }
    }

    getData() {


        this.data.page++;

        let self = this;

        this.data.showLoader = true;

        this.requestsService.getRequests(null, this.data.page, this.data.count).subscribe(response => {
            let data: any = response;

            let requests: any = data.requests;

            if (requests.length < this.data.count) {
                this.data.hasMore = false;
            } else {
                this.data.hasMore = true;
            }



            requests.forEach(request => {
                self.requests.push(request);
            });



            this.data.showLoader = false;


        }, error => {
            this.data.showLoader = false;
        });


    }


}
