import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'app/services/user.service';
import { UtilitiesService } from 'app/services/utilities.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    public data: any = {};
    public focus;
    public focus1;
    public loginSuccess = false;

    constructor(private utilities: UtilitiesService, private userService: UserService, private router: Router) { }

    ngOnInit() {
        var body = document.getElementsByTagName('body')[0];
        body.classList.add('login-page');

        var navbar = document.getElementsByTagName('nav')[0];
        navbar.classList.add('navbar-transparent');
    }
    ngOnDestroy() {
        var body = document.getElementsByTagName('body')[0];
        body.classList.remove('login-page');

        var navbar = document.getElementsByTagName('nav')[0];
        navbar.classList.remove('navbar-transparent');
    }

    login(form) {
        this.utilities.touchedForm(form);
        console.log(this.data);
        console.log(form.valid);
        if (form.valid) {
            this.userService.login(this.data.email, this.data.password).subscribe(response => {
                let data: any = response;

                this.utilities.setData('logged_user', true);
                this.utilities.setData('user', data.user);
                this.utilities.setData('accessToken', data.token);

                this.loginSuccess = true;
                setTimeout(() => {
                    this.router.navigate(['/admin', 'requests']);
                }, 1000);

            }, error => {

                if (error.status == 0 || error.statusText == "Unknown Error") {
                    this.utilities.sendNotification('PLEASE_CHECK_INTERNET', 'danger');

                } else {

                    this.utilities.sendNotification('PLEASE_CHECK_EMAIL_PASSWORD', 'danger');

                }
            });
        }

    }


}
