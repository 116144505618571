import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';
import { PipesModule } from 'app/pipes/pipes.module';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';
import { NouisliderModule } from 'ng2-nouislider';
import { AdminComponent } from './admin.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { LoginComponent } from './login/login.component';
import { AdminLoginsComponent } from './logins/logins.component';
import { AdminPageComponent } from './page/page.component';
import { PagesComponent } from './pages/pages.component';
import { RequestComponent } from './request/request.component';
import { RequestsComponent } from './requests/requests.component';
import { ServiceComponent } from './service/service.component';
import { ServicesComponent } from './services/services.component';
import { SimComponent } from './sim/sim.component';
import { SimsComponent } from './sims/sims.component';
import { SliderComponent } from './slider/slider.component';
import { SlidersComponent } from './sliders/sliders.component';

const components = [AdminComponent, RequestsComponent, RequestComponent, LoginComponent, PagesComponent, AdminPageComponent, SimsComponent, SimComponent, AdminLoginsComponent, LoginPageComponent, SlidersComponent, SliderComponent, ServicesComponent, ServiceComponent];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        NouisliderModule,
        RouterModule,
        JwBootstrapSwitchNg2Module,
        PipesModule,
        RichTextEditorAllModule
    ],
    declarations: components,
    exports: components
})
export class AdminModule { }
