import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PagesService } from 'app/services/pages.service';

@Component({
    selector: 'app-admin-pages',
    templateUrl: './pages.component.html',
    styleUrls: ['./pages.component.scss']
})

export class PagesComponent implements OnInit, OnDestroy {

    public pages = [];

    constructor(public route: ActivatedRoute, private pagesServices: PagesService) {

    }


    ngOnInit() {
        this.pagesServices.getAllPages(null, null, 1000).subscribe(response => {
            this.pages = response.pages;
        });


    }
    ngOnDestroy() {

    }

    delete(pageId) {
        var ask = confirm('هل تريد حقا حذف هذه الصفحة');
        if (ask) {
            let index = this.pages.findIndex(item => item.id == pageId);
            this.pagesServices.deletePage(pageId).subscribe(response => {
                this.pages.splice(index, 1);
            });
        }
    }


}
