import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AdminModule } from './admin/admin.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { BuyModule } from './buy/buy.module';
import { HomeModule } from './home/home.module';
import { LoginModule } from './login/login.module';
import { PageModule } from './page/page.module';
import { LoginsService } from './services/logins.service';
import { PagesService } from './services/pages.service';
import { RequestsService } from './services/requests.service';
import { ServicesService } from './services/services.service';
import { SimsService } from './services/sims.service';
import { SlidersService } from './services/sliders.service';
import { UserService } from './services/user.service';
import { UtilitiesService } from './services/utilities.service';
import { NavbarComponent } from './shared/navbar/navbar.component';
// this is needed!


@NgModule({
    declarations: [
        AppComponent,
        NavbarComponent
    ],
    imports: [
        BrowserAnimationsModule,
        NgbModule.forRoot(),
        FormsModule,
        RouterModule,
        AppRoutingModule,
        AdminModule,
        HomeModule,
        PageModule,
        BuyModule,
        LoginModule,
        HttpClientModule
    ],
    providers: [
        UtilitiesService,
        UserService,
        PagesService,
        SlidersService,
        SimsService,
        RequestsService,
        LoginsService,
        ServicesService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
