import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ErrorInputPipe } from './error';


const pipes = [ErrorInputPipe];
@NgModule({
  declarations: pipes,
  imports: [
    CommonModule
  ],
  exports: pipes
})
export class PipesModule { }
