import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RequestsService } from 'app/services/requests.service';
import { SimsService } from 'app/services/sims.service';
import { UtilitiesService } from 'app/services/utilities.service';

@Component({
    selector: 'app-admin-request',
    templateUrl: './request.component.html',
    styleUrls: ['./request.component.scss']
})

export class RequestComponent implements OnInit, OnDestroy {

    public request: any = { name: '', data: [{ key: '', value: '' }] };
    public sims: any = [];

    public showDetails = false;

    constructor(public route: ActivatedRoute, private requestsRequest: RequestsService, private router: Router, private utilities: UtilitiesService) {
        this.request.id = this.route.snapshot.params.id;
    }


    ngOnInit() {

        if (this.request.id) {
            this.requestsRequest.getRequest(this.request.id).subscribe(response => {
                this.request = response;
                this.showDetails = true;
            });
        } else {
            this.showDetails = true;
        }


    }

    ngOnDestroy() {

    }

    saveRequest(form) {
        if (form.valid) {
            this.requestsRequest.updateRequest(this.request).subscribe(response => {
                this.router.navigate(['/admin', 'requests']);
            });
        }
    }


}
