import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TimerObservable } from 'rxjs/observable/TimerObservable';
import { takeWhile } from 'rxjs/operators';
import { environment } from '../../environments/environment';

declare var google;
declare var $;

@Injectable()
export class UtilitiesService {

    private eventsHandlers: any = {};
    public disabledButtons: any = {};
    public sharedData: any = {};
    private geocoder = null;
    private scrollAnimateAvailable = false;

    constructor(public santitzer: DomSanitizer, public zone: NgZone, private location: Location, private http: HttpClient) {


    }


    disableButton(key, value = true) {
        this.disabledButtons[key] = value;
    }



    touchedForm(form: any) {
        for (let i in form.controls) {
            form.controls[i].touched = true;
        }
    }

    setData(key, data) {
        localStorage.setItem(key, JSON.stringify(data));
    }

    getData(key, defaultValue: any = null) {
        let data = defaultValue;

        try {
            let savedData;
            savedData = localStorage.getItem(key);

            data = JSON.parse(savedData);

        } catch (error) {
            data = defaultValue;
        }

        return data;
    }

    removeData(key) {
        localStorage.removeItem(key);
    }

    clearData() {
        localStorage.clear();

    }


    subscribeEvent(tag, handler) {
        if (!this.eventsHandlers[tag]) {
            this.eventsHandlers[tag] = [];
        }

        let hasString = false;
        if (handler.toString) {
            hasString = true;
        }

        let found = false;
        for (let i in this.eventsHandlers[tag]) {

            if (hasString && this.eventsHandlers[tag][i].toString() == handler.toString()) {
                found = true;
                this.eventsHandlers[tag][i] = handler;
            } else if (!hasString && this.isEqual(this.eventsHandlers[tag][i], handler)) {
                found = true;
                this.eventsHandlers[tag][i] = handler;
            }
        }

        if (!found) {
            this.eventsHandlers[tag].push(handler);
        }

    }

    publishEvent(tag, data = null) {
        if (this.eventsHandlers[tag] && this.eventsHandlers[tag].length > 0) {
            for (let i in this.eventsHandlers[tag]) {
                this.eventsHandlers[tag][i](data);
            }
        }
    }

    publishEventStartWith(tag, data = null) {
        let keys = Object.keys(this.eventsHandlers);
        let searchedFields = [];

        for (let key of keys) {
            if (key.startsWith(tag)) {
                searchedFields.push(key);
            }
        }

        for (let key of searchedFields) {
            if (this.eventsHandlers[key] && this.eventsHandlers[key].length > 0) {
                for (let i in this.eventsHandlers[key]) {
                    this.eventsHandlers[key][i](data);
                }
            }
        }

    }

    removeEvent(tag, handlerId: number = -1) {
        if (this.eventsHandlers[tag]) {
            if (handlerId >= 0) {
                if (this.eventsHandlers[tag][handlerId]) {
                    delete this.eventsHandlers[tag][handlerId];
                }
            } else {

                for (let i in this.eventsHandlers[tag]) {
                    delete this.eventsHandlers[tag][i];
                }
                delete this.eventsHandlers[tag];
            }
        }
    }

    // returns ar for arabic language and en otherwise.
    getDeviceLanguage() {

        // get the language from the browser, this can be like "en", "en-US", "fr", "fr-FR", "es-ES"
        let deviceLanguage = window.navigator.language.toLowerCase();

        if (deviceLanguage.startsWith('ar')) {

            deviceLanguage = 'ar';
        } else {

            deviceLanguage = 'en';
        }

        return deviceLanguage;
    }

    getMapDetails(lat, lng, cb) {


        if (!this.geocoder) {
            this.geocoder = new google.maps.Geocoder();
        }

        this.zone.run(() => {
            this.geocoder.geocode({
                latLng: { lat: lat, lng: lng }
            }, (responses) => {
                this.zone.run(() => {


                    if (responses && responses.length > 0) {
                        let address = (responses[0].formatted_address + '').replace('، الأردن', '');
                        let response = responses[0];

                        cb(address, response, responses);
                    } else {

                        cb(null, null, null);
                    }

                });
            });
        });

    }

    getMapLatLng(address, cb) {


        if (!this.geocoder) {
            this.geocoder = new google.maps.Geocoder();
        }

        this.zone.run(() => {
            this.geocoder.geocode({
                address: address
            }, (responses) => {
                this.zone.run(() => {

                    if (responses && responses.length > 0) {
                        let response = responses[0];
                        cb({ lat: response.geometry.location.lat, lng: response.geometry.location.lng }, response, responses);
                    } else {

                        cb(null, null, null);
                    }

                });
            });
        });

    }

    setTitle(name, logE = true) {
        document.title = name + (logE ? ' - LogE' : '');
    }

    sendNotification(text: string | { text: string, params: Array<{ key: string, value: string }> }, cssClass: any = 'success', duration: any = null) {
        let self = this;

        let translatedText = '';

        // if (typeof text == "object") {
        //     let params = {};

        //     for (let i in text.params) {
        //         params[text.params[i].key] = self.translate.instant(text.params[i].value);
        //     }

        //     translatedText = self.translate.instant(text.text, params);

        // } else {
        //     translatedText = self.translate.instant(text);
        // }

        // if (!duration) {

        //     if (cssClass == 'success') {
        //         duration = 4000;
        //     } else {
        //         duration = 7000;
        //     }
        // }


        // this.snackBar.open(translatedText, '', {
        //     panelClass: [cssClass],
        //     verticalPosition: 'top',
        //     direction: document.dir,
        //     duration: duration,
        // });
    }


    goBack() {
        this.location.back();
    }


    securityUrl(url, type = 'url') {

        if (type == 'url') {
            return this.santitzer.bypassSecurityTrustUrl(url);
        }

        if (type == 'style') {
            return this.santitzer.bypassSecurityTrustStyle(url);
        }

        if (type == 'resource') {
            return this.santitzer.bypassSecurityTrustResourceUrl(url);
        }
    }

    clone(obj) {
        return JSON.parse(JSON.stringify(obj));
    }

    isEqual(obj1, obj2) {
        obj1 = JSON.stringify(obj1);
        obj2 = JSON.stringify(obj2);
        if (obj1 == obj2) {
            return true;
        } else {
            return false;

        }
    }

    getFile(opt: any = { types: ['png', 'jpg', 'jpeg'], size: 4 }): Promise<File> {

        if (!opt) {
            opt = { types: ['png', 'jpg', 'jpeg'], size: 4 };
        }
        if (!opt.size) {
            opt.size = 4;
        }
        if (!opt.types) {
            opt.types = ['png', 'jpg', 'jpeg'];
        }

        let self = this;
        return new Promise((resolve, error) => {
            this.zone.run(() => {
                let fileElement = document.createElement('input');
                fileElement.setAttribute("type", "file");
                fileElement.setAttribute("hidden", "true");
                fileElement.style.display = 'none';
                fileElement.id = 'fileUploadUtilites';
                document.body.append(fileElement);

                fileElement.oncancel = (event) => {
                    fileElement = null;
                    error({ error: 4, type: 'error_no_file', event });
                };

                fileElement.onchange = (event: any) => {
                    self.zone.run(() => {
                        fileElement = null;
                        let file: File = null;
                        if (event.target) {
                            file = event.target.files[0];
                        } else {
                            if (event.path) {
                                file = event.path[0].file;
                            }
                        }

                        if (file != null) {
                            // check size
                            let size = file.size / 1024.0000 / 1024.0000;
                            if (size > opt.size) {
                                self.sendNotification({ text: 'ERROR_FILE_SIZE', params: [{ key: 'size', value: opt.size + '' }] }, 'danger', 4000);
                                error({ error: 1, type: 'error_size', event });
                            }

                            let sameType = false;

                            opt.types.forEach(type => {
                                if (file.type.indexOf(type) >= 0) {
                                    sameType = true;
                                }
                            });

                            if (!sameType) {
                                self.sendNotification({ text: 'ERROR_FILE_TYPE', params: [{ key: 'types', value: opt.types.join(', ') }] }, 'danger', 4000);
                                error({ error: 2, type: 'error_type', event });

                            }

                            let fileE = document.getElementById('fileUploadUtilites');
                            if (fileE) {
                                fileE.remove();
                            }
                            resolve(file);
                        } else {
                            let fileE = document.getElementById('fileUploadUtilites');
                            if (fileE) {
                                fileE.remove();
                            }
                            error({ error: 3, type: 'error_not_support', event });
                        }
                    });

                };

                fileElement.click();
            });


        });

    }

    scrollTop(animation = true) {
        if (animation) {
            $("html, body").animate({ scrollTop: 0 }, "slow");

        } else {
            window.scroll({
                top: 0,
                left: 0,
            });
        }


    }


    animateScrollTo(target: any) {
        let self = this;
        self.scrollAnimateAvailable = true;
        let maxScroll = document.body.offsetHeight - window.innerHeight;
        let timer = TimerObservable
            .create(0, 20).pipe(
                takeWhile(() => self.scrollAnimateAvailable)).subscribe((e) => {
                    if (e == 0) {
                        e++;
                    }
                    if (window.pageYOffset >= target.offsetTop) {
                        window.scrollTo(0, window.pageYOffset - e);

                    } else if (window.pageYOffset <= target.offsetTop) {
                        window.scrollTo(0, window.pageYOffset + e);


                    }


                    if ((window.pageYOffset + 20 > target.offsetTop && window.pageYOffset - 20 < target.offsetTop) || window.pageYOffset == maxScroll) {
                        setTimeout(() => {
                            window.scroll({
                                top: target.offsetTop,
                                left: 0,
                                behavior: 'smooth'
                            });
                        }, 500);


                        self.scrollAnimateAvailable = false;
                        timer.unsubscribe();
                    }

                });


    }

    scrollTo(el: HTMLElement, animation = true) {


        let top = el.getBoundingClientRect().top;

        if (animation) {
            $("html, body").animate({ scrollTop: top }, "slow");

        } else {
            window.scroll({
                top: top,
                left: 0
            });
        }

        // this.animateScrollTo(el);


    }

    getPosition(): Promise<any> {
        return new Promise((resolve, reject) => {

            navigator.geolocation.getCurrentPosition(resp => {

                resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
            },
                err => {
                    reject(err);
                });
        });

    }


    addCountry(countries: Array<any>) {
        countries.unshift({ id: null, name: 'Select Country', name_ar: 'اختر الدولة' });
    }

    addCity(cities: Array<any>) {
        cities.unshift({ id: null, name: 'Select City', name_ar: 'اختر المدينة' });
    }

    deleteNull(array: Array<any>) {

        let index = array.findIndex((value, i, obj) => {
            return value.id == null;
        });

        array.splice(index, 1);
    }

    removeZendDesk() {
        let zendDesk1 = document.getElementById('ze-snippet');
        if (zendDesk1) {
            zendDesk1.remove();
        }

        let zendDesk2 = document.getElementById('launcher');
        if (zendDesk2) {
            zendDesk2.remove();
        }

        let zendDesk3 = document.getElementById('webWidget');
        if (zendDesk3) {
            zendDesk3.remove();
        }

        let zendDesk4 = document.querySelector('[data-product="web_widget"]');
        if (zendDesk4) {
            zendDesk4.remove();
        }
    }



}
