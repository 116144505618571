import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RequestsService } from 'app/services/requests.service';
import { ServicesService } from 'app/services/services.service';
import { SlidersService } from 'app/services/sliders.service';
import { environment } from 'environments/environment';

@Component({
    selector: 'app-buy',
    templateUrl: './buy.component.html',
    styleUrls: ['./buy.component.scss']
})

export class BuyComponent implements OnInit, OnDestroy {

    public slides: any = [];
    public storageUrl = environment.StorageUrl;

    public service: any = { sim: {} };

    public request: any = {};
    public showDoneSend = false;

    public leftSideUrl = (window as any).leftSideUr;


    constructor(public route: ActivatedRoute, private sliders: SlidersService, private servicesService: ServicesService, private requests: RequestsService, private router: Router) {

    }


    ngOnInit() {
        let serviceId = this.route.snapshot.params.id;
        this.sliders.getSliders(null, 0, 10000).subscribe(response => {
            let data: any = response.sliders;
            this.slides = data;
        });
        this.servicesService.getService(serviceId).subscribe(response => {
            this.service = response;
        });
        this.request.service_id = serviceId;
    }
    ngOnDestroy() {

    }


    clickSlide(slide) {
        if (slide.url) {
            if (slide.url.indexOf('http') >= 0) {
                window.location.href = slide.url;

            } else {
                let url = slide.url.split('/');
                this.router.navigate(url);
            }
        }
    }

    openLeftSide() {
        if (this.leftSideUrl) {
            if (this.leftSideUrl.indexOf('http') >= 0) {
                window.location.href = this.leftSideUrl;

            } else {
                let url = this.leftSideUrl.split('/');
                this.router.navigate(url);
            }
        }
    }

    buy(form) {

        if (form.valid) {
            this.requests.createRequest(this.request).subscribe(response => {
                this.showDoneSend = true;
            });
        }
    }

}
