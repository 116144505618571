import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './admin/admin.component';
import { LoginPageComponent } from './admin/login-page/login-page.component';
import { LoginComponent } from './admin/login/login.component';
import { AdminLoginsComponent } from './admin/logins/logins.component';
import { AdminPageComponent } from './admin/page/page.component';
import { PagesComponent } from './admin/pages/pages.component';
import { RequestComponent } from './admin/request/request.component';
import { RequestsComponent } from './admin/requests/requests.component';
import { ServiceComponent } from './admin/service/service.component';
import { ServicesComponent } from './admin/services/services.component';
import { SimComponent } from './admin/sim/sim.component';
import { SimsComponent } from './admin/sims/sims.component';
import { SliderComponent } from './admin/slider/slider.component';
import { SlidersComponent } from './admin/sliders/sliders.component';
import { BuyComponent } from './buy/buy.component';
import { HomeComponent } from './home/home.component';
import { LoginsComponent } from './login/login.component';
import { PageComponent } from './page/page.component';


const routes: Routes = [
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'home', component: HomeComponent },
    { path: 'login', component: LoginsComponent },
    { path: 'page/:id', component: PageComponent },
    { path: 'buy/:id', component: BuyComponent },
    {
        path: 'admin', component: AdminComponent,
        children: [
            { path: 'login', component: LoginComponent },
            { path: 'pages', component: PagesComponent },
            { path: 'pages/add', component: AdminPageComponent },
            { path: 'pages/:id', component: AdminPageComponent },
            { path: 'sims', component: SimsComponent },
            { path: 'sims/add', component: SimComponent },
            { path: 'sims/:id', component: SimComponent },
            { path: 'logins', component: AdminLoginsComponent },
            { path: 'logins/add', component: LoginPageComponent },
            { path: 'logins/:id', component: LoginPageComponent },
            { path: 'sliders', component: SlidersComponent },
            { path: 'sliders/add', component: SliderComponent },
            { path: 'sliders/:id', component: SliderComponent },
            { path: 'services', component: ServicesComponent },
            { path: 'services/add', component: ServiceComponent },
            { path: 'services/:id', component: ServiceComponent },
            { path: 'requests', component: RequestsComponent },
            { path: 'requests/:id', component: RequestComponent },
        ]
    },
    // { path: 'nucleoicons', component: NucleoiconsComponent },
    // { path: 'examples/landing', component: LandingComponent },
    // { path: 'examples/profile', component: ProfileComponent }
];

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule.forRoot(routes)
    ],
    exports: [
    ],
})
export class AppRoutingModule { }
