import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Component, ElementRef, OnInit } from '@angular/core';
import { PagesService } from 'app/services/pages.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    public toggleButton: any;
    public sidebarVisible: boolean;

    public pages: any = [];

    constructor(public location: Location, private element: ElementRef, private pagesServices: PagesService) {
        this.sidebarVisible = false;
        this.pagesServices.getPages(null, null, 10000).subscribe(response => {
            let data: any = response.pages;
            this.pages = data;
        })
    }

    ngOnInit() {
        const navbar: HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
    }
    sidebarOpen() {
        const toggleButton = this.toggleButton;
        const html = document.getElementsByTagName('html')[0];
        setTimeout(function () {
            toggleButton.classList.add('toggled');
        }, 500);
        html.classList.add('nav-open');

        this.sidebarVisible = true;
    };
    sidebarClose() {
        const html = document.getElementsByTagName('html')[0];
        // console.log(html);
        this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        html.classList.remove('nav-open');
    };
    sidebarToggle() {
        // const toggleButton = this.toggleButton;
        // const body = document.getElementsByTagName('body')[0];
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    };


}
