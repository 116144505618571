import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SlidersService } from 'app/services/sliders.service';
import { UtilitiesService } from 'app/services/utilities.service';
import { environment } from 'environments/environment';

@Component({
    selector: 'app-admin-slider',
    templateUrl: './slider.component.html',
    styleUrls: ['./slider.component.scss']
})

export class SliderComponent implements OnInit, OnDestroy {

    public slider: any = { name: '', photo: '' };

    public showDetails = false;

    constructor(public route: ActivatedRoute, private slidersService: SlidersService, private router: Router, private utilities: UtilitiesService) {
        this.slider.id = this.route.snapshot.params.id;


    }


    ngOnInit() {
        if (this.slider.id) {
            this.slidersService.getSlider(this.slider.id).subscribe(response => {
                this.slider = response;
                this.slider.photo = this.slider.photo ? environment.StorageUrl + this.slider.photo : '/assets/img/logo.png';

                this.showDetails = true;
            });
        } else {
            this.slider.photo = '/assets/img/logo.png';
            this.showDetails = true;

        }


    }

    ngOnDestroy() {

    }

    saveSlider(form) {
        let key = this.slider.id ? "updateSlider" : "createSlider";
        let slider = this.utilities.clone(this.slider);
        delete slider.photo;
        delete slider.photoFile;
        delete slider.uploadPhoto;
        let data;

        if (this.slider.uploadPhoto) {
            let photo = this.slider.photoFile;
            data = new FormData();

            for (let i in slider) {
                data.append(i, slider[i]);
            }

            data.append('photoFile', photo, photo.name);

        } else {
            data = slider;
        }

        if (form.valid) {
            this.slidersService[key](data).subscribe(response => {
                this.router.navigate(['/admin', 'sliders']);
            });
        }
    }

    changePhoto() {
        let self = this;
        this.utilities.getFile().then(file => {
            let fileReader = new FileReader();
            this.slider.photoFile = file;
            fileReader.onload = e => {
                self.slider.photo = fileReader.result;
                self.slider.uploadPhoto = true;
            };

            fileReader.readAsDataURL(file);

        }).catch(error => {

        });
    }

}
