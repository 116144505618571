import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PagesService } from 'app/services/pages.service';

@Component({
    selector: 'app-admin-page',
    templateUrl: './page.component.html',
    styleUrls: ['./page.component.scss']
})

export class AdminPageComponent implements OnInit, OnDestroy {

    public page: any = { html: '', name: '', in_menu: true };

    public showDetails = false;

    constructor(public route: ActivatedRoute, private pagesServices: PagesService, private router: Router) {
        this.page.id = this.route.snapshot.params.id;


    }


    ngOnInit() {
        if (this.page.id) {
            this.pagesServices.getPage(this.page.id).subscribe(response => {
                this.page = response;
                this.showDetails = true;
            });
        } else {
            this.showDetails = true;

        }


    }

    ngOnDestroy() {

    }

    savePage(form) {
        let key = this.page.id ? "updatePage" : "createPage";

        if (form.valid) {
            this.pagesServices[key](this.page).subscribe(response => {
                this.router.navigate(['/admin', 'pages']);
            });
        }
    }

}
