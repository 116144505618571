import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServicesService } from 'app/services/services.service';
import { SimsService } from 'app/services/sims.service';
import { UtilitiesService } from 'app/services/utilities.service';
import { environment } from 'environments/environment';

@Component({
    selector: 'app-admin-service',
    templateUrl: './service.component.html',
    styleUrls: ['./service.component.scss']
})

export class ServiceComponent implements OnInit, OnDestroy {

    public service: any = { name: '', data: [{ key: '', value: '' }] };
    public sims: any = [];

    public showDetails = false;

    constructor(public route: ActivatedRoute, private servicesService: ServicesService, private router: Router, private utilities: UtilitiesService, private simsService: SimsService) {
        this.service.id = this.route.snapshot.params.id;
        this.simsService.getSims(null, null, 1000).subscribe(response => {
            this.sims = response.sims;
        });

    }


    ngOnInit() {
        if (this.service.id) {
            this.servicesService.getService(this.service.id).subscribe(response => {
                this.service = response;
                this.showDetails = true;
            });
        } else {
            this.service.photo = '/assets/img/logo.png';
            this.showDetails = true;

        }


    }

    ngOnDestroy() {

    }

    saveService(form) {
        let key = this.service.id ? "updateService" : "createService";

        if (form.valid) {
            this.servicesService[key](this.service).subscribe(response => {
                this.router.navigate(['/admin', 'services']);
            });
        }
    }
    addData() {
        if (this.service.data) {
            this.service.data.push({ key: '', value: '' });
        }
    }

    remove(i) {
        if (i > 0) {
            this.service.data.splice(i, 1);

        }
    }

}
