import { Location } from '@angular/common';
import {
    Component,
    ElementRef,
    OnDestroy,
    OnInit
    } from '@angular/core';
import { Router } from '@angular/router';
import { UtilitiesService } from 'app/services/utilities.service';
import { environment } from 'environments/environment';

@Component({
    selector: 'app-admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.scss']
})

export class AdminComponent implements OnInit, OnDestroy {
    public isLogin = true;
    public toggleButton: any;
    public sidebarVisible: boolean;

    constructor(private utilities: UtilitiesService, public location: Location, private element: ElementRef, private router: Router) {
        this.utilities.publishEvent('set-admin');
        this.isLogin = !this.utilities.getData('logged_user');
        if (this.isLogin) {
            this.router.navigate(['/admin', 'login']);
        }

    }


    ngOnInit() {
        const navbar: HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
    }
    ngOnDestroy() {
        this.utilities.publishEvent('remove-admin');

    }
    sidebarOpen() {
        const toggleButton = this.toggleButton;
        const html = document.getElementsByTagName('html')[0];
        setTimeout(function () {
            toggleButton.classList.add('toggled');
        }, 500);
        html.classList.add('nav-open');

        this.sidebarVisible = true;
    };
    sidebarClose() {
        const html = document.getElementsByTagName('html')[0];
        // console.log(html);
        this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        html.classList.remove('nav-open');
    };
    sidebarToggle() {
        // const toggleButton = this.toggleButton;
        // const body = document.getElementsByTagName('body')[0];
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    };

    logout() {
        this.utilities.clearData();
        window.location.reload();
    }

}
