import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoginsService } from 'app/services/logins.service';

@Component({
    selector: 'app-admin-logins',
    templateUrl: './logins.component.html',
    styleUrls: ['./logins.component.scss']
})

export class AdminLoginsComponent implements OnInit, OnDestroy {

    public logins = [];

    constructor(public route: ActivatedRoute, private loginsService: LoginsService) {

    }


    ngOnInit() {
        this.loginsService.getLogins(null, null, 1000).subscribe(response => {
            this.logins = response.logins;
        });

    }
    ngOnDestroy() {

    }

    delete(loginId) {
        var ask = confirm('هل انت متأكد من انك تريد الحذف');
        if (ask) {
            let index = this.logins.findIndex(item => item.id == loginId);
            this.loginsService.deleteLogin(loginId).subscribe(response => {
                this.logins.splice(index, 1);
            });
        }
    }


}
