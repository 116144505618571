import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginsService } from 'app/services/logins.service';
import { SlidersService } from 'app/services/sliders.service';
import { environment } from 'environments/environment';


@Component({
    selector: 'app-logins',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})

export class LoginsComponent implements OnInit, OnDestroy {

    public slides: any = [];
    public storageUrl = environment.StorageUrl;

    public logins: any = [];

    public leftSideUrl = (window as any).leftSideUr;
    constructor(private loginsServices: LoginsService, private sliders: SlidersService, private router: Router) {

    }


    ngOnInit() {
        this.sliders.getSliders(null, 0, 10000).subscribe(response => {
            let data: any = response.sliders;
            this.slides = data;
        });
        this.loginsServices.getLogins(null, 0, 10000).subscribe(response => {
            let data: any = response.logins;
            this.logins = data;
        });
    }
    ngOnDestroy() {

    }

    clickSlide(slide) {
        if (slide.url) {
            if (slide.url.indexOf('http') >= 0) {
                window.location.href = slide.url;

            } else {
                let url = slide.url.split('/');
                this.router.navigate(url);
            }
        }
    }

    openLeftSide() {
        if (this.leftSideUrl) {
            if (this.leftSideUrl.indexOf('http') >= 0) {
                window.location.href = this.leftSideUrl;

            } else {
                let url = this.leftSideUrl.split('/');
                this.router.navigate(url);
            }
        }
    }

}
