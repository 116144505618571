import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServicesService } from 'app/services/services.service';

@Component({
    selector: 'app-admin-services',
    templateUrl: './services.component.html',
    styleUrls: ['./services.component.scss']
})

export class ServicesComponent implements OnInit, OnDestroy {

    public services = [];

    constructor(public route: ActivatedRoute, private servicesService: ServicesService) {

    }


    ngOnInit() {
        this.servicesService.getServices(null, null, 1000).subscribe(response => {
            this.services = response.services;
        });

    }
    ngOnDestroy() {

    }

    delete(serviceId) {
        var ask = confirm('هل انت متأكد من انك تريد الحذف');
        if (ask) {
            let index = this.services.findIndex(item => item.id == serviceId);
            this.servicesService.deleteService(serviceId).subscribe(response => {
                this.services.splice(index, 1);
            });
        }
    }


}
