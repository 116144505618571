import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SimsService } from 'app/services/sims.service';

@Component({
    selector: 'app-admin-sims',
    templateUrl: './sims.component.html',
    styleUrls: ['./sims.component.scss']
})

export class SimsComponent implements OnInit, OnDestroy {

    public sims = [];

    constructor(public route: ActivatedRoute, private simsService: SimsService) {

    }


    ngOnInit() {
        this.simsService.getSims(null, null, 1000).subscribe(response => {
            this.sims = response.sims;
        });

    }
    ngOnDestroy() {

    }

    delete(simId) {
        var ask = confirm('هل تريد حقا حذف هذه الصفحة');
        if (ask) {
            let index = this.sims.findIndex(item => item.id == simId);
            this.simsService.deleteSim(simId).subscribe(response => {
                this.sims.splice(index, 1);
            });
        }
    }


}
